<div class="table-responsive">
    <table class="table table-borderless table-striped mb-0" [ngClass]="borderless ? 'table-borderless' : 'table-bordered'">
        <tbody>
            <tr *ngIf="showMethod">
                <td class="font-weight-bold text-medium">{{ 'common.method' | translate }}</td>
                <td class="text-uppercase">{{ beneficiary?.method }}</td>
            </tr>
            <tr>
                <td class="font-weight-bold text-medium">{{ (remitter ? 'common.remitter' : 'common.beneficiary') | translate }}</td>
                <td>{{ beneficiary?.accountHolder }}</td>
            </tr>
            <tr>
                <td class="text-muted font-sm">- {{ getAccountNumberLabel() }}</td>
                <td>{{ beneficiary?.accountNumber }}</td>
            </tr>
            <tr *ngIf="beneficiary.userType === 'personal'">
                <td class="text-muted font-sm">- {{ 'common.dateOfBirth' | translate }}</td>
                <td>{{ beneficiary?.details.birthday }}</td>
            </tr>
            <tr *ngIf="beneficiary.userType === 'business'">
                <td class="text-muted font-sm">- {{ 'withdraw.registration-number' | translate }}</td>
                <td>{{ beneficiary?.details.registrationNumber }}</td>
            </tr>
            <ng-container *ngIf="beneficiary && (beneficiary.method === 'swift' || beneficiary.method === 'local')">
                <tr *ngIf="layout !== 'metal'">
                    <td class="text-muted font-sm">- {{ 'common.address' | translate }}</td>
                    <td>
                        <postal-address [address]="beneficiary?.accountHolderAddress"></postal-address>
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold text-medium">{{ (remitter ? 'common.remitter-bank' : 'common.beneficiary-bank') | translate }}</td>
                    <td>{{ beneficiary?.bankName }}</td>
                </tr>
                <tr *ngIf="showSwift()">
                    <td class="text-muted font-sm">- {{ getSwiftLabel() }}</td>
                    <td>{{ beneficiary?.bankSwiftCode }}</td>
                </tr>
                <tr *ngIf="showRoutingNumber()">
                    <td class="text-muted font-sm">- {{ getRoutingNumberLabel() }}</td>
                    <td>{{ beneficiary?.routingNumber }}</td>
                </tr>
                <tr *ngIf="layout !== 'metal'">
                    <td class="text-muted font-sm">- {{ 'common.address' | translate }}</td>
                    <td>
                        <postal-address [address]="beneficiary?.bankAddress"></postal-address>
                    </td>
                </tr>
                <ng-container *ngIf="beneficiary?.intermediateBankName">
                    <tr>
                        <td class="font-weight-bold text-medium">{{ 'common.intermediate-bank' | translate }}</td>
                        <td>{{ beneficiary?.intermediateBankName }}</td>
                    </tr>
                    <tr>
                        <td class="text-muted font-sm">- {{ 'common.swift-code' | translate }}</td>
                        <td>{{ beneficiary?.intermediateBankSwiftCode }}</td>
                    </tr>
                    <tr>
                        <td class="text-muted font-sm">- {{ 'common.address' | translate }}</td>
                        <td>
                            <postal-address [address]="beneficiary?.intermediateBankAddress"></postal-address>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <tr *ngIf="showCurrency">
                <td class="font-weight-bold text-medium">{{ 'common.currency' | translate }}</td>
                <td class="text-uppercase">{{ beneficiary?.currency?.code }}</td>
            </tr>
            <ng-container *ngIf="transaction">
                <tr *ngIf="transaction.type === 'deposit'">
                    <td class="font-weight-bold text-medium">{{ 'common.amount' | translate }}</td>
                    <td class="text-primary">
                        {{ transaction.amount | amount : transaction.currency?.decimalPlaces }} {{ transaction.currency?.code }}
                    </td>
                </tr>
                <tr *ngIf="transaction.type === 'withdrawal'">
                    <td class="font-weight-bold text-medium">{{ 'common.amount' | translate }}</td>
                    <td class="text-primary">
                        {{ calculateCreditableAmount(transaction) | amount : transaction.currency?.decimalPlaces }}
                        {{ transaction.currency?.code }}
                    </td>
                </tr>
                <tr *ngIf="transaction.id">
                    <td class="font-weight-bold text-medium">{{ 'common.reference' | translate }}</td>
                    <td>
                        {{ transaction.id.substring(0, 7).toUpperCase() }}
                    </td>
                </tr>
                <tr>
                    <td class="p-0" colspan="2">
                        <div
                            *ngIf="showWarning && ['pending', 'processing'].includes(transaction.status) && transaction.type === 'deposit'"
                            class="callout callout-danger whitespace-normal mb-0"
                        >
                            <h6 class="text-danger mb-0">{{ 'common.important-notice' | translate }}</h6>
                            <p>{{ 'common.if-you-specify' | translate }}</p>
                            <p *ngIf="layout === 'metal'">
                                {{ 'common.by-clicking-mark-as-paid' | translate }}
                            </p>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
