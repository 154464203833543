import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core'
import { faCloudUpload, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { File as IFile } from '../api-interfaces'

@Component({
    selector: 'dropzone',
    templateUrl: 'dropzone.component.html',
})
export class DropzoneComponent implements AfterViewInit {
    @Input()
    public files: File[] = []
    @Output()
    public readonly filesChange = new EventEmitter<File[]>()
    @Input()
    public existingFiles?: IFile[]
    @Input()
    public showTable = true
    @Input()
    public canCreate = true
    @Input()
    public canDelete = true
    @Output()
    public readonly onDelete = new EventEmitter<IFile>()
    @Input()
    public requiredMessage: string
    public isDragValid: boolean
    public isDragInvalid: boolean
    public submitted: boolean
    public faCloudUpload = faCloudUpload
    public faTimes = faTimes
    @Input()
    public allowVideo = false
    @Input()
    public height = '160px'
    @Input()
    public required = false

    public ngAfterViewInit(): void {
        setTimeout(() => {
            if (!Array.isArray(this.files)) {
                this.filesChange.emit([])
            }
        })
    }

    public submit(): void {
        this.submitted = true
    }

    public isValid(): boolean {
        return (
            this.required &&
            ((!!this.files && this.files.length > 0) || (!!this.existingFiles && this.existingFiles.length > 0))
        )
    }
}
